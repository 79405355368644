<template>
  <router-view class="app" />
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { Geolocation } from "@capacitor/geolocation";
import { App as CapacitorApp } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { FCM } from "@capacitor-community/fcm";
export default {
  name: "App",
  data() {
    return {
      registration: null,
      updateExists: false,
      updateFound: true,
      isReady: false,
    };
  },
  created() {
    if (this.$store.state.auth.user !== null) {
      this.$root.$i18n.locale = this.$store.state.auth.user.lang;
    }

    // Se encontro una version nueva
    document.addEventListener("swUpdatefound", this.updatefound, {
      once: true,
    });

    document.addEventListener("swReady", this.ready, {
      once: true,
    });

    document.addEventListener("swCached", this.cache, {
      once: true,
    });

    /*document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });

    navigator.serviceWorker.addEventListener("controllerchange", () => {
      window.location.reload();
    });*/

    
  },
  mounted() {
    this.checkPermissionGps();
    if (Capacitor.platform !== "web") {
      //this.registerPush();
      CapacitorApp.addListener("backButton", ({ canGoBack }) => {
        if (!canGoBack) {
          CapacitorApp.exitApp();
        } else {
          window.history.back();
        }
      });
    }
  },
  computed: {
    ...mapState(["watchID"]),
  },
  methods: {
    ...mapMutations({
      setGeolocationData: "setGeolocationData",
      update: "update",
      updateDowload: "updateDowload",
      setRegistration: "setRegistration",
    }),
    ...mapActions(["initGpsTracking"]),
    registerPush() {
      PushNotifications.requestPermissions().then(async (permission) => {
        if (permission.receive === "granted") {
          // Register with Apple / Google to receive push via APNS/FCM
          await PushNotifications.register();
         
        } else {
          // No permission for push granted
        }
      });
      // On success, we should be able to receive notifications
      PushNotifications.addListener("registration", (token) => {
        alert("Push registration success, token: " + token.value);
         // Enable the auto initialization of the library
          FCM.setAutoInit({ enabled: true });
          // Check the auto initialization status
          FCM.isAutoInitEnabled();
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener("registrationError", (error) => {
        alert("Error on registration: " + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => {
          alert("Push received: " + JSON.stringify(notification));
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification) => {
          alert("Push action performed: " + JSON.stringify(notification));
        }
      );
    },
    updateAvailable(event) {
      this.setRegistration(event.detail);
      this.update(true);
    },
    ready() {
      this.isReady = true;
    },
    updatefound() {
      if (this.isReady) {
        this.updateDowload(true);
        this.$router.push({ name: "Update" });
      }
    },
    async checkPermissionGps(){
      try{
        await this.initGpsTracking();
      }catch(err){
        console.log("------------>",err);
      }
    }
  },
  destroyed() {
    Geolocation.clearWatch(this.watchID);
  },
};
</script>

<style lang="scss">
.app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
</style>

<style>
body {
  overflow-x: hidden;
}
</style>
