import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { Geolocation } from "@capacitor/geolocation";
import {Capacitor as CapacitorApp} from '@capacitor/core'
Vue.use(Vuex);

// Modulos
import ui from "./ui";
import auth from "./Auth";
import services from "./services";
import chat from "./chat";

export default new Vuex.Store({
  namespaced: true,
  plugins: [
    createPersistedState({
      key: "rider",
      paths: [
        "auth.user",
        "auth.access_token",
        "services.checkin",
        "services.id",
        "ui.navigation.IndexItemActive",
      ],
    }),
  ],
  state: {
    lang: "es",
    platform: CapacitorApp.getPlatform() || 'web',
    updateExists: false,
    registration: null,
    updateDowload: false,
    geolocationData: {},
    watchID: null,
    gpsGranted: false
  },
  getters: {
    isIOS:(state) => state.platform === 'ios',
  },
  mutations: {
    setLang(state, lang) {
      state.lang = lang;
    },
    setGeolocationData(state, payload) {
      //console.log(payload);
      state.geolocationData = payload;
    },
    setWatchId(state, payload) {
      state.watchID = payload;
    },
    setRegistration(state, reg) {
      state.registration = reg;
    },
    update(state, val) {
      state.updateExists = val;
    },
    updateDowload(state, val) {
      state.updateDowload = val;
    },
    setGpsGranted(state,val){
      state.gpsGranted=val;
    }
  },
  actions: {
    async getLocationPWA(){
      if ("geolocation" in navigator) {
        return new Promise((resolve,reject)=>{
          navigator.geolocation.getCurrentPosition(
            position => {
              const { latitude, longitude } = position.coords;
              if (latitude == null || latitude===0 ) {
                const errorMessage = latitude === null ? "Latitude null" : "Latitude 0";
                reject(errorMessage);
              } else {
                const data = {
                  latitude,
                  longitude,
                  speed: position.coords.speed || 0,
                  gps: true,
                };
                resolve(data);
              }
              //console.log(this.location)
            },
            error => {
              console.error("Error getting location:", error.message);
              reject(error.message);
            }
          );
        })
      } else {
        console.error("Geolocation is not supported by this browser.");
        throw "Geolocation is not supported by this browser.";
      }
    },
    async gpsFoPWA({commit}){
      if ("geolocation" in navigator) {
        //console.log("starting gps");
        return new Promise((resolve,reject)=>{
          navigator.geolocation.getCurrentPosition(
            position => {
              const { latitude, longitude } = position.coords;
              if (latitude == null || latitude===0 ) {
                const errorMessage = latitude === null ? "Latitude null" : "Latitude 0";
                commit("setGeolocationData", { gps: false });
                commit("setGpsGranted",false);
                reject(errorMessage);
              } else {
                const data = {
                  latitude,
                  longitude,
                  speed: position.coords.speed || 0,
                  gps: true,
                };
                commit("setGeolocationData", data);
                commit("setGpsGranted",true);
                resolve(data);
                //console.log("Permission granted")
              }
              //console.log(this.location)
            },
            error => {
              console.error("Error getting location:", error.message);
              commit("setGpsGranted",false);
              return reject({gps:"forbidden"});
            }
          );
        })
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },
    async initGpsTracking({ state,commit,dispatch }) {
      if (!state.watchID) {
        if(state.platform =='web'){
          return await dispatch('gpsFoPWA');
        }
        const permission = await Geolocation.checkPermissions();
        if(permission.location != 'granted') return await Geolocation.requestPermissions()
        return await new Promise((resolve, reject) => {
          try {
            const watchId = Geolocation.watchPosition(
              (position) => {
                const latitude = position.coords.latitude;
                if (latitude == null) {
                  commit("setGeolocationData", { gps: false });
                  reject("Latitude null");
                } else if (latitude === 0) {
                  commit("setGeolocationData", { gps: false });
                  reject("Latitude 0");
                } else {
                  const data = {
                    latitude,
                    longitude: position.coords.longitude,
                    speed: position.coords.speed || 0,
                    gps: true,
                  };
                  commit("setWatchId", watchId);
                  commit("setGeolocationData", data);
                  resolve(data);
                }
              },
              () => {
                commit("setGeolocationData", { gps: false });
                reject("Error");
              },
              {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: 30000,
              }
            );
          } catch (e) {
            console.log(e)
            commit("setGeolocationData", { gps: false });
            reject("No gps");
          }
        });
      }
      return;
    }
  },
  modules: {
    ui,
    services,
    auth,
    chat,
  },
});
